import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'; 

const App = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the email field is empty
        if (email.trim() === '') {
            toast.error('Please enter your email.');
            return;
        }

        setLoading(true); // Show loading sign
        try {
            const response = await fetch('https://script.google.com/macros/s/AKfycby694joaSgO_bgrAhdaxJgR1qyXqkXwm-V0fuKMF3jhnzaAN8Gan5L_We1nLBn--Ddnxw/exec', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `email=${encodeURIComponent(email)}`,
            });
            if (response.ok) {
                toast.success('Thank you for signing up!');
                setEmail(''); // Clear the email input
                setShowSuccessDialog(true); // Show success dialog
            } else {
                toast.error('Something went wrong. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Something went wrong. Please try again later.');
        }
        setLoading(false); // Hide loading sign
    };

    const handleOkClick = () => {
        setShowSuccessDialog(false); // Close the dialog box
    };

    return (
      <div className="landing-container">
        <ToastContainer /> {/* Toast container */}
        <div className='logo'>
          <img src={require('./finlablogo.png')} alt="FinLab Logo" className="logo" /> 
        </div>
        <div className="content">
          <h1 className="title">The Game That Teaches You Investing</h1>
          <p className="subtitle">App Coming Soon</p>
          <form className="waitlist" onSubmit={handleSubmit}>
            <input 
              type="email" 
              placeholder="Enter your email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
            <button type="submit">{loading ? 'Joining...' : 'Join the Waitlist'}</button>
          </form>
        </div>
        {showSuccessDialog && (
          <div className="dialog-container">
            <div className="dialog">
              <h2>Awesome!</h2>
              <p>You're on the early access list. Watch your inbox for your beta invite in the coming weeks.</p>
              <button onClick={handleOkClick}>Ok</button>
            </div>
          </div>
        )}
      </div>
    );
};

export default App;
